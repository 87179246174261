// lead-section
// =========================================================================
.lead-section {
	padding: 2rem 0;

	.container-one-column {
		.container-holder {
			max-width: 750px;
			margin: 0 auto;
			text-align: center;
			@include media-breakpoint-down(lg) {
				max-width: unset;
				margin: unset;
				text-align: unset;
				padding-left: unset;
				padding-right: unset;
				ol, ul {
					li {
						margin-bottom: 0.75rem;
					}
				}
			}
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}

	.custom-list {
		display: flex;
		flex-wrap: wrap;
		gap: 10px 2rem;
		text-align: left;
		font-family: $font-family-special-1;
		color: $primary;

		@include media-breakpoint-up(sm) {
			justify-content: center;
			font-size: $small-font-size;
		}
	}
}

&.home {
	.lead-section {
		.container-one-column {
			.container-holder {
				max-width: 100%;
			}
		}
	}
}

// review section

.review-section {
	margin: 6vh auto;
	// review-create
	.review-create {
		form {
			@extend .form;
			border: none;
			background: rgba(#fff, 0.5);
			margin-top: 3vh;

			.rating {
				color: $primary;
				font-size: 24px;
			}
		}
	}

	// reviews-overview
	.reviews-overview {
		.item {
			margin-top: 30px;
			background: rgba(#fff, 0.5);
			.review {
				padding: 15px;

				.rating {
					margin: 15px 0;
					color: $primary;
					font-size: 24px;

					i {
						margin-right: 2px;
					}
				}

				.reviewer {
					font-size: 0.9rem;
					font-style: italic;
				}

				.date {
					font-size: 0.8rem;
				}
			}
		}
	}
	.js-review-complete {
		margin: 3vh auto;
		background: #6da46d;
		color: $white;
		font-weight: bold;
		p {
			margin: 0;
		}
	}
}

// content-section
// =========================================================================
.content-section {
	padding: 1.5rem 0;

	@include media-breakpoint-up(lg) {
		padding: 3rem 0;
	}

	.float-img {
		position: absolute;
		bottom: 0;
		left: -2rem;

		@include media-breakpoint-up(sm) {
			left: -4rem;
		}

		@include media-breakpoint-up(lg) {
			left: -4.75rem;
		}

		img {
			@include media-breakpoint-down(lg) {
				max-width: 65%;
			}
		}
	}

	.container {
		position: relative;
		z-index: 1;
	}
}

// collection-section
// =========================================================================
.collection-section {
	padding: 3.5rem 0;
}

// banner-section
// =========================================================================
.banner-section {
	position: relative;

	.owl-carousel,
	.owl-carousel .item {
		aspect-ratio: 1 / 1.25;

		@include media-breakpoint-up(md) {
			aspect-ratio: 2 / 1;
		}
	}

	.float-img {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: 2;

		img {
			@include media-breakpoint-down(sm) {
				max-width: 140px;
			}
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	padding: 3.5rem 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	padding: 3.5rem 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	padding: 3.5rem 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	padding: 3.5rem 0;
}
