// mixins

// adds vendor prefixes
@mixin prefix($property, $value) {
	$prefixes: webkit, moz, ms, o;
	@each $prefix in $prefixes {
		-#{$prefix}-#{$property}: $value;
	}
	#{$property}: $value;
}

// icon size
@mixin icon-size($size) {
    font-size: $size; 
	width: $size;
	height: $size;
	line-height: $size;
}

// add style to all headings tags
@mixin headings-style {
	@for $i from 1 through 6 {
		h#{$i},
		.h#{$i} {
			@content;
		}
	}
}

/// Stroke font-character
/// @param  {Integer} $stroke - Stroke width
/// @param  {Color}   $color  - Stroke color
/// @return {List}            - text-shadow list
@function stroke($stroke, $color) {
	$shadow: ();
	$from: $stroke * -1;
	@for $i from $from through $stroke {
		@for $j from $from through $stroke {
			$shadow: append($shadow, $i * 1px $j * 1px 0 $color, comma);
		}
	}
	@return $shadow;
}
/// Stroke font-character
/// @param  {Integer} $stroke - Stroke width
/// @param  {Color}   $color  - Stroke color
/// @return {Style}           - text-shadow
@mixin stroke($stroke, $color) {
	text-shadow: stroke($stroke, $color);
}
