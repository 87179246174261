.faq-overview {
	margin-top: 1rem;
	> .faq-category {
		margin: 0;
		border-top: 1px solid $primary;

		&:last-child {
			border-bottom: 1px solid $primary;
		}
	}

	.faq-category {
		margin: 0;

		.faq-title {
			padding: 1rem 50px 1rem 0;

			h2 {
				color: $primary;
				font-size: $h3-font-size;
			}

			.faq-icon {
				.fa-chevron-up::before {
					content: "\f068";
				}
			}

			&:hover {
				h2,
				.faq-icon {
					color: $primary;
				}
			}
		}
		.faq-items {
			.faq-item {
				&.active {
					.faq-icon {
						.fa::before {
							content: "\f068";
						}
					}
				}

				&.active,
				&:hover {
					.faq-question {
						h3 {
							color: $primary;
						}
					}

					.faq-icon {
						color: $primary;
					}
				}

				&:not(:last-child) {
					border-bottom: 1px solid $primary;
				}

				.faq-question {
					border: none;
					padding: 1rem 50px 1rem 0;
					margin: 0;

					h3 {
						color: $primary;
						font-size: $h6-font-size;
						transition: 0.2s ease;
						font-family: $font-family-primary;
						font-weight: 700;
					}

					&:hover {
						.faq-icon {
							color: $primary;
						}
					}
				}

				.faq-answer {
					padding: 0;
					border: none;
					margin: 0;
					font-size: $small-font-size;

					a {
						color: $primary;
						&:hover {
							color: $primary;
						}
					}
				}
			}
		}
	}

	.faq-icon {
		transition: 0.2s ease;
		right: 0;
		left: unset;
		font-size: 18px;
		height: 100%;
		display: flex !important;
		justify-content: flex-end;
		align-items: center;
		color: $primary;

		.fa::before {
			content: "\f067";
		}
	}
}
