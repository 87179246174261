// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 100vh;
	min-height: 300px;
	max-height: 430px;

	@include media-breakpoint-up(lg) {
		max-height: 680px;
	}
}

.eyecatcher .owl-carousel .item {
	&::before {
		content: "";
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		left: 50%;
		z-index: 1;
		background-image: linear-gradient(to right, rgba($orange, 0), $orange);
	}

	.owl-caption-holder {
		position: relative;
		z-index: 2;
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 100vh;
	min-height: 400px;
	max-height: 500px;
	background-position: left center;

	@include media-breakpoint-up(lg) {
		max-height: 1080px;
		background-position: center center;
	}

	.owl-container {
		max-width: 360px;

		@include media-breakpoint-up(sm) {
			max-width: 470px;
		}

		@include media-breakpoint-up(lg) {
			max-width: 830px;
		}
	}
}

.eyecatcher {
	position: relative;
	margin-top: 0;
	overflow: hidden;

	&::after {
		content: "";
		display: block;
		position: absolute;
		bottom: -4px;
		left: -2px;
		right: -2px;
		height: 40px;
		line-height: 0;
		z-index: 1;
		background: url("/images/eyecatcher-clip-mobile.svg") top center no-repeat;
		background-size: cover;

		@include media-breakpoint-up(sm) {
			margin-top: 90px;
			height: 300px;
			background: url("/images/eyecatcher-clip.svg") bottom center no-repeat;
		}
	}

	&:not(:has(+ .mini-sab)) {
		&::after {
			@include media-breakpoint-down(sm) {
				height: 11vw;
				background: url("/images/eyecatcher-clip.svg") bottom center no-repeat;
			}
		}
	}

	.owl-carousel {
		.item {
			.owl-title {
				font-size: $h2-font-size;
				color: $primary;
				@include stroke(2, $white);

				@include media-breakpoint-up(lg) {
					@include stroke(4, $white);
					font-size: $h1-font-size;
				}

				@include media-breakpoint-up(xl) {
					font-size: 80px;
				}
			}
		}
	}

	.float-image {
		position: absolute;
		right: 8px;
		bottom: 10px;
		z-index: 999;
		pointer-events: none;
		z-index: 800;
		img {
			max-width: 130px;
		}

		@include media-breakpoint-up(sm) {
			bottom: 6rem;
		}

		@include media-breakpoint-up(lg) {
			bottom: 15%;
			right: 30px;

			img {
				max-width: 100%;
			}
		}
	}
}