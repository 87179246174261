// btn
.btn {
	box-shadow: $shadow;
	font-weight: 700;
	padding: .7rem 1.875rem;
	font-size: $small-font-size;
	border-radius: 5px;

	// btn-primary
	&.btn-primary {
		&:hover {}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {}
	}

	&::after {
		content: '\f0a9';
		display: inline-block;
		font-family: $font-awesome;
		font-size: $h6-font-size;
		font-weight: 900;
		vertical-align: middle;
		margin-left: .5rem;
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
