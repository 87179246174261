section:not(.mini-sab) {
	.page-block {
		&.title {
			@include headings-style {
				@include stroke(3, $white);
			}

			.subtitle {
				color: $orange;
				margin-top: 5px;
				font-family: $font-family-special;
			}
		}

		&.subtitle {
			@include headings-style {
				color: $orange;
				margin-top: 5px;
				font-family: $font-family-special;
			}
		}

		&.page-title {
			@include headings-style {
				@include stroke(3, $white);
			}
		}
	}
}

.page-block {
    &.form {
        background-color: $white;
        font-size: $small-font-size;

        .form-control {
            background-color: $body-bg;
        }
    }
}
