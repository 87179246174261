// header
.header {
	transition: 0.3s;
	background-image: linear-gradient($white 53%, rgba($white, 0) 10%);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	@include media-breakpoint-up(lg) {
		position: absolute;
		z-index: 1000;
	}

	&.menu-open {
		background-image: linear-gradient($white, $white);
		z-index: 99999 !important;
	}

	&::after {
		background: url("/images/header-bg-transparent.svg") top center no-repeat,
					url("/images/header-bg-solid.svg") top center no-repeat;
		background-size: 100%;
		content: "";
		display: block;
		position: absolute;
		top: 2.25rem;
		left: 0;
		right: 0;
		height: 17vw;
		z-index: -1;

		@include media-breakpoint-up(lg) {
			top: 0;
		}

		@include media-breakpoint-up(xl) {
			top: -1rem;
		}
	}
	.container,
	.container-fluid {
		.container-holder {
			> .column {
				align-items: flex-start;

				@include media-breakpoint-up(sm) {
					padding: 0 30px;
				}

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@include media-breakpoint-up(lg) {
					padding: 0 40px;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		.navbar-toggler {
			color: $primary;
			font-size: 1.25rem;
			padding: 1.5rem;
			margin-left: -1.5rem;
		}
	}

	// logo
	.logo {
		flex: 0 0 154px;
		max-width: 154px;
		margin: 15px 20px 10px 0;

		@media (min-width: 361px) {
			flex: 0 0 140px;
			max-width: 140px;
		}

		@include media-breakpoint-up(sm) {
			flex: 0 0 250px;
			max-width: 250px;
		}
		@include media-breakpoint-up(lg) {
			flex: 0 0 250px;
			max-width: 250px;
		}

		@include media-breakpoint-up(xl) {
			flex: 0 0 320px;
			max-width: 320px;
			margin-top: 2.5rem;
		}

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// book button
	div.book-btn {
		margin-top: 1rem;
		margin-left: 1rem;

		order: 6;
		a {
			@extend .btn, .btn-primary;
			padding: 8px !important;
		}
		@include media-breakpoint-down(lg) {
			display: none;
		}
	}

	&:has(.navbar-toggler[aria-expanded="true"]) div.book-btn {
		@include media-breakpoint-down(lg) {
			display: block;
			margin: 1rem auto;
		}
	}

	// menu
	.menu {
		@extend .justify-content-lg-end;

		.navbar-nav {
			.dropdown-menu {
				.dropdown {
					.dropdown-toggle::after {
						border-top: 0.3em solid transparent;
						border-bottom: 0.3em solid transparent;
						border-right: 0;
						border-left: 0.3em solid;
						vertical-align: middle;
					}

					.dropdown-menu::before,
					.dropdown-menu::after {
						border-left: unset;
						right: 100%;
						left: auto;
					}

					.dropdown-menu::before {
						top: 7px;
						border-right: 9px solid #e1e1e1;
						border-bottom: 9px solid transparent;
						border-top: 9px solid transparent;
					}

					.dropdown-menu::after {
						top: 8px;
						border-right: 8px solid #fff;
						border-bottom: 8px solid transparent;
						border-top: 8px solid transparent;
					}

					.dropdown-menu {
						top: 0 !important;
						left: 100% !important;
						transform: none !important;
						width: 300px;
					}
				}
			}

			.nav-item {
				.nav-link {
					font-size: $small-font-size;
					text-align: center;

					&.dropdown-item {
						background-color: unset;
						color: $link-color;

						&:hover {
							color: $link-hover-color;
						}
					}
				}

				&.active {
					> .nav-link {
						color: $link-hover-color;
					}
				}
			}
		}

		@include media-breakpoint-down(lg) {
			order: 5;
			width: 100vw;
			.navbar-nav {
				padding-top: 1rem;

				.nav-link {
					font-weight: 600;
					font-size: 16px !important;
				}
			}

			.dropdown-menu {
				padding: 0 1rem;
				border: none;

				&.show {
					position: static !important;
					transform: none !important;
					.nav-link {
						font-weight: 200;
						font-size: 12px;
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			margin-top: 1rem;

			.navbar-nav {
				margin-right: -1rem;
				.nav-item {
					.nav-link {
						padding-left: 1rem !important;
						padding-right: 1rem !important;
					}
				}
			}
		}
	}

	// searchbox
	.searchbox {
		position: relative;
		min-width: 14px;
		margin-left: 1.5rem;

		.form-control {
			display: block;
			position: absolute;
			top: 3.25rem;
			right: 0;
			width: 172px;
			height: 30px;
			z-index: 1;
			transition: $transition-base;
			opacity: 0;
			pointer-events: none;
			transition: 0.5s ease;
			&::placeholder {
				color: $primary !important;
			}
			@include media-breakpoint-down(lg) {
				width: 80vw !important;
				left: -90vw !important;
				margin: auto;
			}
			@media (min-width: 361px) {
				top: 4.75rem;
				width: 250px;
				height: 6vh;
				font-size: 16px;
				font-weight: 600;
				border: 2px solid $primary;
				outline: 0;
				z-index: 1000;
			}

			@include media-breakpoint-up(sm) {
				top: 1.75rem;
			}

			&.show {
				opacity: 1;
				pointer-events: all;
				transition: 0.5s ease;
			}
		}

		.search-icon {
			display: block;
			color: $link-color;
			text-align: center;
			transition: $transition-base;
			cursor: pointer;

			&.hide {
				display: none;
			}

			&:hover {
				color: $link-hover-color;
			}
		}
	}

	// footer-logolink
	.footer-logolink {
		.list {
			column-gap: 1.5rem;

			a {
				i {
					margin-right: 0.5rem;
				}
			}
		}
	}

	// header-top
	.header-top {
		font-weight: 700;
		font-size: $small-font-size;
		padding-top: 14px;

		@include media-breakpoint-down(lg) {
			display: none;
		}
	}

	// header-main
	.header-main {
		.column {
			display: flex !important;
			align-items: center !important;

			@include media-breakpoint-up(xl) {
				height: 7vh;
			}
		}
		.footer-logolink {
			margin-left: auto;
			flex: 0 0 50px;
			max-width: 50%;
			.list {
				column-gap: 1rem;

				a {
					.list-item-title {
						display: none;
					}
					i {
						margin-right: 0;
					}
				}
			}
		}

		.searchbox {
			margin-left: 1rem;
		}

		.searchbox,
		.footer-logolink {
			margin-top: -1.5rem;
			@include media-breakpoint-up(xl) {
				display: none;
			}
		}
	}
	// site-switcher
	// .site-switcher {}

	// sticky
	&.sticky {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 998;
		background-color: $white;

		.header-main {
			.container-holder {
				.column {
					height: auto;
				}
			}
		}

		&::after,
		&::before {
			display: none;
		}

		.header-top {
			display: none;
		}

		.header-main {
			padding-bottom: 0;
		}

		.logo {
			max-width: 100px;
			margin-top: 0.5rem;
		}

		.menu {
			.navbar-nav {
				padding-top: 0;
			}
			@include media-breakpoint-up(lg) {
				margin-top: 0.5rem;
				margin-right: 1rem;
			}
		}

		.menu-toggle {
			.navbar-toggler {
				padding-top: 0.5rem;
				padding-bottom: 0.5rem;
				margin-top: 0.5rem;
			}
		}

		.searchbox {
			.form-control {
				top: 1.75rem;
			}
		}

		.searchbox,
		.footer-logolink {
			display: block;
			order: 4;
			margin-top: 0.5rem;
		}
		div.book-btn {
			margin-right: 1rem;
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;
		}

		@include media-breakpoint-down(sm) {
			.container,
			.container-fluid {
				.container-holder {
					> .column {
						padding: 0 15px;
					}
				}
			}
		}
	}
}
