.gallery-horizontal {
    .gallery-label {
        background-color: $secondary;
        color: $white;
        padding: 5px 15px;
        top: 2rem;
    }
}

.gallery-horizontal,
.gallery {
    .gallery-item {
        .gallery-link {
            border-radius: 10px;
        }
    }
}
