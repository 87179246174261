// zoom fix
@media (-webkit-device-pixel-ratio: 1.25) and (min-width: 1024px) {
	html {
		zoom: 0.8;
	}
}
// container
.container {
	&.container-small {
		max-width: 600px;
	}

	&.container-medium {
		max-width: 790px;
	}

	&.container-intro {
		max-width: 790px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 790px;
		margin-top: 30px;
		text-align: center;
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
	object-fit: cover;
	border-radius: 10px;
}

iframe {
	max-width: 100%;
}

// ancher link
a {
	&:hover {
		text-decoration: none;
	}
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.3s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// spacing
.custom-mb-n {
	margin-bottom: -220%;

	@include media-breakpoint-up(sm) {
		margin-bottom: -60%;
	}

	@include media-breakpoint-up(md) {
		margin-bottom: -25%;
	}

	@include media-breakpoint-up(xxl) {
		margin-bottom: -16%;
	}
}

.custom-pt {
	padding-top: 220% !important;

	@include media-breakpoint-up(sm) {
		padding-top: 60% !important;
	}

	@include media-breakpoint-up(md) {
		padding-top: 25% !important;
	}

	@include media-breakpoint-up(xxl) {
		padding-top: 16% !important;
	}
}

.main {
	.container {
		.container-holder {
			@include media-breakpoint-down(xs) {
				padding-left: 40px;
				padding-right: 40px;
			}

			@media (max-width: 400px) {
				padding-left: 30px;
				padding-right: 30px;
			}
		}
	}
}

// default-card
.default-card {
	border-radius: 10px;

	.card-caption {
		.card-title {
			color: $primary;
		}

		.card-subtitle {
			color: $orange;
			font-family: $font-family-special;
		}

		.card-description {
			font-size: $small-font-size;
		}

		.list.list-icons {
			.list-item {
				.icon {
					font-size: $h6-font-size;
				}

				.label {
					text-align: left;
				}
			}
		}
	}
}

// waves
.top-wave {
	position: relative;
	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 300px;
		background: url('/images/top-wave.svg') bottom center no-repeat;
		background-size: contain;
		position: absolute;
		top: -300px;
		left: 0;
	}
}

.bottom-wave {
	position: relative;
	&::after {
		content: '';
		display: block;
		width: 100%;
		height: 300px;
		background: url('/images/bottom-wave-white-transparent.svg') bottom center no-repeat;
		background-size: contain;
		position: absolute;
		bottom: -2px;
		left: 0;
		z-index: 1;
	}
}
