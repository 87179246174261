// mini-sab

.mini-sab {
	position: relative;
	z-index: 800;

	background: url("/images/eyecatcher-clip-mobile.svg") bottom center no-repeat;
	background-size: cover;

	@include media-breakpoint-up(sm) {
		margin-bottom: 0;
		background: none;
	}

	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			flex: 1;
			z-index: 100;
			position: relative;
			margin: 0 30px;
			padding: 2rem 5px;
			border-radius: 4px;
			background: $secondary;
			box-shadow: 3px 3px 6px rgba($black, 0.15);
			color: #fff;

			// below eyecatcher
			@include media-breakpoint-up(sm) {
				margin-top: -140px;
				margin-bottom: 20px;
				max-width: 480px;
			}
			@include media-breakpoint-up(lg) {
				margin: 0;
				margin-top: -150px;
				margin-bottom: auto;
				padding: 15px 20px;
				max-width: 1024px;
			}
		}
	}

	.page-block.title {
		@include media-breakpoint-down(sm) {
			text-align: center;
		}

		@include headings-style {
			font-family: $font-family-special-1;
			color: $black;
		}
	}

	&.sticky {
		min-height: 386px;
		@include media-breakpoint-up(sm) {
			min-height: 143px;
		}

		@include media-breakpoint-up(lg) {
			min-height: unset;
		}

		.container {
			max-width: 100%;
			.container-holder {
				margin: 0 -15px;
				padding: 0.5rem 0;
				border-radius: 0;
				top: 58px;
				position: fixed;
				width: 100%;
				max-width: 100%;

				@include media-breakpoint-up(lg) {
					padding-top: 12px;
					padding-bottom: 12px;
				}
			}
		}

		.page-block.title {
			display: none;
		}

		@include media-breakpoint-down(lg) {
			.w3-vue-booking-wrapper.w3-minisearchbook-widget {
				.widget-element {
					.filters {
						justify-content: flex-end;
						.filter-element {
							&:not(.filter-context-button) {
								display: none;
							}

							&.filter-context-button {
								margin: 0;
								flex: 0 0 auto;
							}
						}
					}
				}

				.widget-btn {
					height: auto;
					padding: 5px 14px;
				}
			}
		}
	}
}

// hide mini-sab
&.accommodation-overview,
&.accommodation-category-overview,
&.accommodation-category-detail,
&.accommodation-search-book,
&.accommodation-book,
&.accommodation-book-error,
&.accommodation-book-succes,
&.accommodation-book-terms-and-conditions,
&.accommodation-detail {
	.mini-sab {
		display: none;
	}
}
