.card {
	// overlay card
	&.card-overlay {
		border-radius: 10px;
		overflow: visible;

		.card-image {
			border-radius: 10px;

			.card-img {
				@include media-breakpoint-down(sm) {
					aspect-ratio: 1;
				}
			}
		}

		&:hover {
			.card-img {
				transform: scale(1.05);
			}
		}

		.card-img-overlay {
			text-align: center;
			border-radius: 10px;
			overflow: visible;
		}

		.card-caption {
			height: 100%;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			text-shadow: none;

			.card-title {
				color: $primary;
				@include stroke(2, $white);
				margin-top: -3rem;
				word-wrap: normal;
				font-size: $h1-font-size;

				@include media-breakpoint-up(sm) {
					font-size: $h2-font-size;
				}

				@include media-breakpoint-up(lg) {
					font-size: $h1-font-size;
				}
			}

			.fa-arrow-circle-right {
				margin-top: auto;
				background-image: radial-gradient(
					closest-side,
					$secondary 85%,
					$white 85%
				);
				flex: 0 0 $h3-font-size;
				max-width: $h3-font-size;
				font-size: $h3-font-size;
				border-radius: 99px;

				@include media-breakpoint-up(lg) {
					font-size: $h1-font-size;
					flex: 0 0 $h1-font-size;
					max-width: $h1-font-size;
				}

				&:hover {
					background-image: radial-gradient(
						closest-side,
						$primary 85%,
						$white 85%
					);
				}
			}

			.card-content {
				position: relative;
				margin-top: 1rem;
				line-clamp: 3;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				text-overflow: ellipsis;
				word-wrap: break-word;
				overflow: hidden;
			}

			.card-description {
				position: relative;
				font-size: $small-font-size;
				height: 100%;
				display: flex;
				align-items: center;
				min-height: 7rem;
				max-height: 7rem;
				@include media-breakpoint-up(lg) {
					font-size: $font-size-base;
				}
			}
		}
	}

	&.icon {
		text-align: center;
		background-color: unset;
		border: none;

		.card-image {
			min-height: 100px;
			display: flex;
			justify-content: center;
			align-items: flex-end;
		}

		.card-title {
			font-size: $h4-font-size;
			color: $primary;
			@include stroke(2, $white);
		}

		.card-description {
			color: $primary;
			font-size: $small-font-size;
		}
	}

	.card-image {
		.card-image-caption {
			bottom: auto;
			right: auto;
			top: 1rem;
			.card-image-label {
				background-color: $secondary;
			}
		}
	}
}
