// footer
.footer {

    .container-fluid {
        .container-holder {
			>.column {
                position: static;
				justify-content: flex-end;
				padding: 0 28px;
            }
        }
    }

    // footer-image
    .footer-image {
        position: absolute;
        bottom: -2rem;
        right: 30px;

        @include media-breakpoint-up(md) {
            bottom: -3rem;
        }
    }

    // footer-sitelinks
    .footer-sitelinks {
        position: relative;
        
        padding: 3rem 0 15.5rem;
        @include media-breakpoint-up(md) {
            padding: 3rem 0;
        }
        a {
            &:hover {
                color: $orange !important;
            }
        }
    }

    // footer-logolink
    .footer-logolink {
        .list {
            justify-content: center;
            flex-direction: column;
            row-gap: .5rem;

            .list-item {

                .link {
                    text-decoration: none !important;
    
                    i {
                        font-size: $small-font-size;
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    // social-media
    ul.social-media {
        justify-content: center;
        column-gap: 1rem;

        li {
            a {
                text-decoration: none !important;

                i {
                    font-size: $h3-font-size;
                }

                .list-item-title {
                    display: none;
                }
            }
        }
    }

    // footer-copyright {
    .footer-copyright {
        padding: 1.25rem 0;
    }

    // footer-link
    .footer-link {
        .list {
            column-gap: 1rem;
            justify-content: center;

            @include media-breakpoint-up(lg) {
                justify-content: flex-end;
                column-gap: 2rem;
            }

            .link {
                font-size: 12px;
            }
        }
    }

    ul.footer-logos {
        align-items: flex-end;
        justify-content: center;
        column-gap: 2.5rem;
        margin-bottom: 2rem;

        @include media-breakpoint-up(md) {
            justify-content: flex-start;
            margin-bottom: 0;
            align-items: center;
        }
        li {
            a {
                text-decoration: none !important;
            }

            img {
                border-radius: unset;
            }

            .list-item-title {
                display: none;
            }
        }
    }
}
