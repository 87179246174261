&.search {
	main {
		.searchbox {
			.form-control {
				margin-right: 0.5rem;
			}

			.search-icon {
				@extend .btn;
				@extend .btn-primary;

				padding: 0.5rem 1.875rem;
				height: 38px;

				&::after {
					// display: none;
				}
			}
		}
	}
}

.search-results {
	.search_alert {
		margin-top: 1rem;
	}
}

.search-form-holder {
	display: flex;
}
