.collection {
	&.grid {
		.grid-items {
			.item {
				@include media-breakpoint-up(md) {
					@include make-col(4);
				}

				.card-buttons .btn {
					padding: 0;
					color: $primary;
					background: transparent;
					border: 0;
					box-shadow: none;
					&::after {
						transition: 0.35s ease;
					}
					&:hover {
						&::after {
							transform: translateX(5px);
							transition: 0.35s ease;
						}
					}
				}
			}
		}

		&.long-card {
			.grid-items {
				margin: 0 -15px -50px -15px;
				.item {
					margin: 0 0 50px 0;
				}
			}
		}
	}

	&.faciliteit-collection {
		.item {
			justify-content: center;
		}

		.owl-stage {
			padding-top: 4rem;
		}
		.card {
			.card-caption {
				.fa-arrow-circle-right {
					margin-top: 0;
					transform: translateY(1rem);
				}
			}

			.card-subtitle,
			.card-description-content {
				display: none;
			}

			// .card-caption::before {
			//     top: 50%;
			//     transform: translate(-50%, -50%);

			//     @include icon-size($h3-font-size);

			//     @include media-breakpoint-up(lg) {
			//         @include icon-size($h2-font-size);
			//     }
			// }

			.card-title {
				font-size: $h4-font-size;
				@include stroke(1, $white);
			}
		}
	}
}
